import * as THREE from 'three';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';

let DEFAULT_SCALE = 1.0;

let scene, camera;
var renderer;
var rotCurrent;
var mesh;
let controls;

export default function Renderer({container})
{
  scene  = new THREE.Scene();
  camera = new THREE.PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 0.01, 100 );
  camera.position.z = 10;
  scene.add( camera );

  renderer = new THREE.WebGLRenderer({antialias:true, alpha:true});
  renderer.setPixelRatio( window.devicePixelRatio );
  renderer.setSize( window.innerWidth, window.innerHeight );

  document.body.appendChild( container );
  container.appendChild(renderer.domElement);

  controls = new OrbitControls( camera, renderer.domElement );
  controls.target.setScalar(0);
  controls.update();
  controls.enablePan = true;

  const geometry = new THREE.PlaneGeometry( 16, 9 );
  var tex = new THREE.TextureLoader().load(window.rendTexture, // Get texture size via globals
                                             function ( tex ) { window.tex_width = tex.image.width;
                                                                window.tex_height = tex.image.height; });
  tex.colorSpace = THREE.SRGBColorSpace;
  var mat = new THREE.MeshBasicMaterial({ map: tex, transparent: true, side: THREE.DoubleSide });
  mesh = new THREE.Mesh(geometry, mat);
  mesh.scale.setScalar(DEFAULT_SCALE);
  scene.add(mesh);
  rotCurrent = window.rotAngle = 0;

  window.addEventListener( 'resize', onWindowResize );
  renderer.setAnimationLoop( render );
}

// On resize 
function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize( window.innerWidth, window.innerHeight );
}

// Render
function render() {
  if( window.rendPause )
    return;
   
  if(window.rotAngle !== rotCurrent)
  { 
    mesh.rotation.z = THREE.MathUtils.degToRad(-window.rotAngle);
    rotCurrent = window.rotAngle;
  }

  if(window.needsContReset)
  {
    controls.reset();
    window.needsContReset = false;
  }

  if(window.needsTexUpdate)
  {
    mesh.material.map = new THREE.TextureLoader().load( window.rendTexture,
                                                        ()=>{ mesh.material.map.dispose();
                                                              mesh.material.map.needsUpdate = true;
                                                              window.needsTexUpdate = false; });
    mesh.material.map.colorSpace = THREE.SRGBColorSpace;
  }

  controls.update();
  renderer.render( scene, camera );
}
