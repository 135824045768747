import Browser from './Browser';
import Renderer from './Renderer';
import './App.css';

export default function App() {

  let cont = document.createElement('div');
  cont.style.visibility = 'hidden';
  window.rendPause = true;

  return (<div>
          <Renderer container={cont}/>
          <Browser  rend={cont} startPage={0}/>
         </div>);
}
