import React, { useState } from 'react';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import { styled } from '@mui/material/styles';
import PrevIcon from '@mui/icons-material/ArrowBackIos';
import NextIcon from '@mui/icons-material/ArrowForwardIos';
import HelpIcon from '@mui/icons-material/HelpOutline';
import AppsIcon from '@mui/icons-material/Apps';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Link from '@mui/material/Link';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Viewer from './Viewer';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import CloseIcon from '@mui/icons-material/Close';
import CanvasIcon from '@mui/icons-material/Grid4x4';
import CameraIcon from '@mui/icons-material/Videocam';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import RotationIcon from '@mui/icons-material/Cached';
import MailIcon from '@mui/icons-material/MailOutline';

const DESIGNS_DIR = './images/designs/';
const drawerWidth = 340;
var gView;

/* About */
const AboutDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

/* AppBar */
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),

  background: '#bbaa88',
  color: '#292929'
}));

// Make thumbnail
function makeThumb(url)
{
  let len = url.length;
  let idx = url.length - 4;
  let thumb = url.substring(0, idx) + "_THUMB" + url.substring(idx, len);
  return thumb;
}

//
// Browser
//
export default function Browser({rend, startPage}) {
  // Fetch DB data
  let recs="";
  var xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        recs = JSON.parse(xhttp.responseText); // Fill content here

        for(let i=0; i < recs.length; i++)
          recs[i].thumb = makeThumb(recs[i].image_url);

        let add = 12 - recs.length;
        for(let i=0; i < add; i++)
           recs.push({id: -1});
      }
  };

  // Paging
  let lastPage = 2 // TODO: get it from request
  const [page, setPage] = useState(startPage);

  // Category selection
  const [category, setCat] = useState('');
  const handleCategory = (event: SelectChangeEvent) => {
    setCat(event.target.value);
    setPage(0);
  };

  // Check on upload (!)
  var req = "https://embview.povlab.online/list_designs.php?page=" + page + "&category=" + category;
  // var req = "http://localhost:8080/embview/list_designs.php?page=" + page + "&category=" + category;
  xhttp.open("GET", req, false);
  xhttp.send();

  // About
  const [about, openAbout] = React.useState(false);

  // Design selection
  const [selected, setSelected] = useState(false);
  function handleSelect(r) {
    gView = <Viewer  rend={rend} recs={recs} recCur={r} page={page}/>
    setSelected(true);
  }
  if(selected)
    return (gView);

  //
  // Return
  //
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
        <IconButton
          onClick={()=>setPage(page - 1)}
          sx={{position:'absolute', top:'482px', left:'calc(50vw - 680px)', visibility: page === 0 ? 'hidden' : 'visible', opacity: '0.9'}}>
          <PrevIcon />
        </IconButton>
        <IconButton
          onClick={()=>setPage(page + 1)}
          sx={{position:'absolute', top:'482px', left:'calc(50vw + 645px)', visibility: page === lastPage ? 'hidden' : 'visible', opacity: '0.9'}}>
          <NextIcon />
        </IconButton>
        <Grid container spacing={0} style={{ position: 'absolute', top:'75px', left:'calc(50vw - 600px)', width:'1200px' }}>
          {/* ---------------------------------- 0 --------------------------------- */}
          <Grid item xs={3}>
            <Card sx={{ maxWidth: 280, visibility: recs[0].id < 0 ? 'hidden' : 'visible' }} 
                  onClick={() => handleSelect(0)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image={ DESIGNS_DIR + recs[0].thumb}
                  alt={recs[0].name}/>
                <CardContent align='center'>
                  <Typography variant="body2">
                    <Link href={recs[0].vendor_url} target="_blank"> {recs[0].vendor}</Link> / {recs[0].name}
                  </Typography>
                  <Typography variant="body4" color="text.secondary" sx={{ fontStyle:'italic' }}>
                    {recs[0].size_mm} mm / {recs[0].size_st} St.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ maxWidth: 280, visibility: recs[1].id < 0 ? 'hidden' : 'visible' }} 
                  onClick={() => handleSelect(1)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image={ DESIGNS_DIR + recs[1].thumb}
                  alt={recs[1].name}/>
                <CardContent align='center'>
                  <Typography variant="body2">
                    <Link href={recs[1].vendor_url} target="_blank"> {recs[1].vendor}</Link> / {recs[1].name}
                  </Typography>
                  <Typography variant="body4" color="text.secondary" sx={{ fontStyle:'italic' }}>
                    {recs[1].size_mm} mm / {recs[1].size_st} St.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ maxWidth: 280, visibility: recs[2].id < 0 ? 'hidden' : 'visible' }} 
                  onClick={() => handleSelect(2)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image={ DESIGNS_DIR + recs[2].thumb}
                  alt={recs[2].name} />
                <CardContent align='center'>
                  <Typography variant="body2">
                    <Link href={recs[2].vendor_url} target="_blank"> {recs[2].vendor}</Link> / {recs[2].name}
                  </Typography>
                  <Typography variant="body4" color="text.secondary" sx={{ fontStyle:'italic' }}>
                    {recs[2].size_mm} mm / {recs[2].size_st} St.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ maxWidth: 280, visibility: recs[3].id < 0 ? 'hidden' : 'visible' }} 
                  onClick={() => handleSelect(3)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image={ DESIGNS_DIR + recs[3].thumb}
                  alt={recs[3].name}/>
                <CardContent align='center'>
                  <Typography variant="body2">
                    <Link href={recs[3].vendor_url} target="_blank"> {recs[3].vendor}</Link> / {recs[3].name}
                  </Typography>
                  <Typography variant="body4" color="text.secondary" sx={{ fontStyle:'italic' }}>
                    {recs[3].size_mm} mm / {recs[3].size_st} St.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          {/* ---------------------------------- 4 --------------------------------- */}
          <Grid item xs={3}>
            <Card sx={{ maxWidth: 280, visibility: recs[4].id < 0 ? 'hidden' : 'visible' }} 
                  onClick={() => handleSelect(4)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image={ DESIGNS_DIR + recs[4].thumb}
                  alt={recs[4].name} />
                <CardContent align='center'>
                  <Typography variant="body2">
                    <Link href={recs[4].vendor_url} target="_blank"> {recs[4].vendor}</Link> / {recs[4].name}
                  </Typography>
                  <Typography variant="body4" color="text.secondary" sx={{ fontStyle:'italic' }}>
                    {recs[4].size_mm} mm / {recs[4].size_st} St.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ maxWidth: 280, visibility: recs[5].id < 0 ? 'hidden' : 'visible' }} 
                  onClick={() => handleSelect(5)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image={ DESIGNS_DIR + recs[5].thumb}
                  alt={recs[5].name} />
                <CardContent align='center'>
                  <Typography variant="body2">
                    <Link href={recs[5].vendor_url} target="_blank"> {recs[5].vendor}</Link> / {recs[5].name}
                  </Typography>
                  <Typography variant="body4" color="text.secondary" sx={{ fontStyle:'italic' }}>
                    {recs[5].size_mm} mm / {recs[5].size_st} St.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ maxWidth: 280, visibility: recs[6].id < 0 ? 'hidden' : 'visible' }} 
                  onClick={() => handleSelect(6)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image={ DESIGNS_DIR + recs[6].thumb}
                  alt={recs[6].name}/>
                <CardContent align='center'>
                  <Typography variant="body2">
                    <Link href={recs[6].vendor_url} target="_blank"> {recs[6].vendor}</Link> / {recs[6].name}
                  </Typography>
                  <Typography variant="body4" color="text.secondary" sx={{ fontStyle:'italic' }}>
                    {recs[6].size_mm} mm / {recs[6].size_st} St.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ maxWidth: 280, visibility: recs[7].id < 0 ? 'hidden' : 'visible' }} 
                  onClick={() => handleSelect(7)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image={ DESIGNS_DIR + recs[7].thumb}
                  alt={recs[7].name}/>
                <CardContent align='center'>
                  <Typography variant="body2">
                    <Link href={recs[7].vendor_url} target="_blank"> {recs[7].vendor}</Link> / {recs[7].name}
                  </Typography>
                  <Typography variant="body4" color="text.secondary" sx={{ fontStyle:'italic' }}>
                    {recs[7].size_mm} mm / {recs[7].size_st} St.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          {/* ---------------------------------- 8 --------------------------------- */}
          <Grid item xs={3}>
            <Card sx={{ maxWidth: 280, visibility: recs[8].id < 0 ? 'hidden' : 'visible' }}
                  onClick={() => handleSelect(8)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image={ DESIGNS_DIR + recs[8].thumb}
                  alt={recs[8].name}/>
                <CardContent align='center'>
                  <Typography variant="body2">
                    <Link href={recs[8].vendor_url} target="_blank"> {recs[8].vendor}</Link> / {recs[8].name}
                  </Typography>
                  <Typography variant="body4" color="text.secondary" sx={{ fontStyle:'italic' }}>
                    {recs[8].size_mm} mm / {recs[8].size_st} St.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ maxWidth: 280, visibility: recs[9].id < 0 ? 'hidden' : 'visible' }}
                  onClick={() => handleSelect(9)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image={ DESIGNS_DIR + recs[9].thumb}
                  alt={recs[9].name}/>
                <CardContent align='center'>
                  <Typography variant="body2">
                    <Link href={recs[9].vendor_url} target="_blank"> {recs[9].vendor}</Link> / {recs[9].name}
                  </Typography>
                  <Typography variant="body4" color="text.secondary" sx={{ fontStyle:'italic' }}>
                    {recs[9].size_mm} mm / {recs[9].size_st} St.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ maxWidth: 280, visibility: recs[10].id < 0 ? 'hidden' : 'visible' }} 
                  onClick={() => handleSelect(10)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image={ DESIGNS_DIR + recs[10].thumb}
                  alt={recs[10].name}/>
                <CardContent align='center'>
                  <Typography variant="body2">
                    <Link href={recs[10].vendor_url} target="_blank"> {recs[10].vendor}</Link> / {recs[10].name}
                  </Typography>
                  <Typography variant="body4" color="text.secondary" sx={{ fontStyle:'italic' }}>
                    {recs[10].size_mm} mm / {recs[10].size_st} St.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ maxWidth: 280, visibility: recs[11].id < 0 ? 'hidden' : 'visible' }} 
                  onClick={() => handleSelect(11)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image={ DESIGNS_DIR + recs[11].thumb}
                  alt={recs[11].name}/>
                <CardContent align='center'>
                  <Typography variant="body2">
                    <Link href={recs[11].vendor_url} target="_blank">{recs[11].vendor}</Link> / {recs[11].name}
                  </Typography>
                  <Typography variant="body4" color="text.secondary" sx={{ fontStyle:'italic' }}>
                    {recs[11].size_mm} mm / {recs[11].size_st} St.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>

      {/* Logo
      <img src='images/tech/logo.png' width='220px'
           style={{position: 'absolute', left:'-35px', top:'-67px', zIndex:999, cursor: 'pointer'}}
           alt='logo'
           onClick={()=>{ window.location.reload() }}/>
      */}
      <AppBar position="fixed" className={"main"} style={{zIndex:998}}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={()=>{ window.location.reload() }}>
            <AppsIcon />
          </IconButton>
          <Typography variant="h5" noWrap sx={{ flexGrow: 1, fontStyle:'italic', opacity: '0.9', position:'relative' }} component="div">
            Essential embroidery collection
          </Typography>
          {/* Select category */}
          <FormControl sx={{ m: 1, minWidth: 120}} size="small" className={"main"}>
            <InputLabel id="demo-select-small-label" className={"main"}>Category</InputLabel>
            <Select className={"main"}
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={category}
              label="Category"
              onChange={handleCategory}>
              <MenuItem value={0}><em>- None -</em></MenuItem>
              <MenuItem value={3}><em>Nature</em></MenuItem>
              <MenuItem value={11}><em>Garden</em></MenuItem>
              <MenuItem value={1}><em>Flowers</em></MenuItem>
              <MenuItem value={13}><em>Insects</em></MenuItem>
              <MenuItem value={10}><em>Butterflies</em></MenuItem>
{/*
             <MenuItem value={2}><em>Fishes</em></MenuItem>
             <MenuItem value={4}><em>Fonts</em></MenuItem>
             <MenuItem value={6}><em>Birds</em></MenuItem>
             <MenuItem value={7}><em>Sports</em></MenuItem>
             <MenuItem value={8}><em>Dragons</em></MenuItem>
             <MenuItem value={9}><em>Letters</em></MenuItem>
*/}
            </Select>
          </FormControl>

          {/* DEBUG: Make  global settings (?)
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: 'none' }) }}>
            <MenuIcon />
          </IconButton>
          */}

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={()=>openAbout(true)}
            sx={{ opacity: '0.8' }}>
            <HelpIcon />
          </IconButton>

        </Toolbar>
      </AppBar>

      {/* About */}
      <AboutDialog
        onClose={()=>openAbout(false)}
        aria-labelledby="customized-dialog-title"
        open={about}>
      
        <DialogTitle sx={{ m: 0, p: 2, background: '#bbaa88'}} id="customized-dialog-title">
          <Typography variant="h6" sx={{fontStyle:'italic', opacity: '0.8'}}>  
            <em>Wellcome to "Essential embroidery collection" !</em>
          </Typography>
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={()=>openAbout(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            background: '#bbaa88'
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{background: '#e7dabf'}}>
          <Typography gutterBottom> 
            Click on design to view it in more details. Use mouse to scale ( <em>Wheel</em> ), rotate ( <em>LeftClick+Drag</em> ) and position ( <em>RightClick+Drag</em> ) design on a canvas (<CanvasIcon sx={{opacity: '0.8', position: 'relative', top:'7px'}}/>), sample clodes (<CheckroomIcon sx={{opacity: '0.8', position: 'relative', top:'5px'}}/>) or on your own materials, viewed by camera (<CameraIcon sx={{opacity: '0.8', position: 'relative', top:'7px'}}/>).
          </Typography>
          <Typography>
            Use slider on toolbar to rotate design in screen plane and reset ( <RotationIcon sx={{opacity: '0.8', position: 'relative', top:'7px'}}/> ) all transformations to default values.
          </Typography>
        </DialogContent>

        <DialogActions sx={{background: '#e7dabf'}}>
          <Typography sx={{background: '#e7dabf', position: 'relative', right: '10px'}}>
            <Link href={'mailto:syanenko65@gmain.com'} target="_blank"><MailIcon sx={{opacity: '0.8', position: 'relative', top:'7px'}}/></Link> / <Link href={'https://www.linkedin.com/in/sergey-yanenko-57b21a96/'} target="_blank"><em>LinkedIn</em></Link>
          </Typography>
        </DialogActions>

      </AboutDialog>
    </Box>
  );
}
