// TODO
// - Jeens cloths ... 
// - Fix blue color in filter by category
// - Double rendering ?
// - Make logos: (manifest.json)
// - About

import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import Slider from '@mui/material/Slider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ListItem from '@mui/material/ListItem';

// Icons
import ListItemIcon from '@mui/material/ListItemIcon';
import LeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CanvasIcon from '@mui/icons-material/Grid4x4';
import CameraIcon from '@mui/icons-material/Videocam';
import AppsIcon from '@mui/icons-material/Apps';
import RotationIcon from '@mui/icons-material/Cached';
import CheckroomIcon from '@mui/icons-material/Checkroom';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

import Browser from './Browser';
import { ChromePicker } from 'react-color';
import Link from '@mui/material/Link';

// TODO: Move to incude
const DESIGNS_DIR = './images/designs/';

var gRecCur  = -1;
var gRendNum = 0;
var gCloth   = 1;
var gCanvas = 1;
var gBackColor = {hex: "#6d6047"}; // Default as in 'index.css'
var gBackMode = 2; // Checkroom
const drawerWidth = 340;
/*
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
    position: 'relative',
  }),
);
*/
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),

  background: '#bbaa88',
  color: '#292929'
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',

}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

//
// Camera
//
var camera_on = false;
function startCamera() {
  if(camera_on)
    return;
  var video = document.getElementById('video');
  if(video == null)
    return;
  if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    navigator.mediaDevices.getUserMedia({ video: true }).then(function(stream) {
      camera_on = true;
      if( video.srcObject !== null )
        stopCamera();
      video.srcObject = stream;
    });
  }
};

function stopCamera() {
  if(!camera_on)
    return;
  camera_on = false;
  let video = document.getElementById('video');
  if(video == null)
    return;
  const stream = video.srcObject;
  if(video.srcObject == null)
    return;
  const tracks = stream.getTracks();

  tracks.forEach((track) => {
    track.stop();
  });

  video.srcObject = null;
}

//
// Viewer
//
export default function Viewer( {rend, recs, recCur, page} ) {
  const theme = useTheme();
  if(gRecCur < 0)
    gRecCur = recCur;
   
  // Drawer control
  const [open, setOpen] = useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Force rerender
  // reRender(!click);
  const [click, reRender] = useState(false);
  gRendNum++;

  // Background mode
  const [bg, setBack] = useState(gBackMode);
  const handleBackChange = (bg) => (event, expanded) => {
    setBack(bg);
    gBackMode = bg; // TODO: Complete it
    if(expanded) // Init all types
    {
      if(bg === 0)
        startCamera();
      else
        stopCamera();

      if(bg === 1)
        handleCanvasChange(undefined, gCanvas);

      if(bg === 2)
        handleClothingChange(undefined, gCloth);
    }
  };

  // Change canvas
  const handleCanvasChange = (event, canvas) => {
    let html = document.getElementsByTagName('html')[0];
    html.style.backgroundRepeat = "repeat";
    html.style.backgroundImage = "url('/images/canvas/canvas_" +  canvas + ".jpg')";
    html.style.backgroundBlendMode = "overlay";
    gCanvas = canvas;
  };
  
  // Change back color
  const [backColor, setBackColor] = useState(gBackColor);
  const handleBackColorChange = (backColor) => {
    let html = document.getElementsByTagName('html')[0];
    html.style.backgroundColor = "rgba(" + backColor.rgb.r + "," +
                                           backColor.rgb.g + "," +
                                           backColor.rgb.b + "," +
                                           backColor.rgb.a + ")";
    setBackColor(backColor);
    gBackColor = backColor;
  };

  // Change Clothing
  const handleClothingChange = (event, cloth) => {
    let html = document.getElementsByTagName('html')[0];
    html.style.backgroundRepeat = "no-repeat";
    html.style.backgroundPosition = "center top 60px";
    html.style.backgroundImage = "url('images/products/prod_" + cloth + ".png')";
    html.style.backgroundBlendMode = "normal"
    gCloth = cloth;
  };
  if(gBackMode === 2)
    handleClothingChange(undefined, gCloth);

  // Back to browser
  const [back, getBack] = useState(false);
  if(back){
    gRendNum = 0;
    gRecCur = -1;

    // Stop Three rendering
    rend.style.visibility = 'hidden';
    window.rendPause = true;

    // Change camera mode to canvas
    /*
    if(gBackMode === 0)
    {
      stopCamera();
      gBackMode = 1;
      handleCanvasChange(undefined, gCanvas);
    }
    */

    return(<Browser rend={rend} startPage={page}/>);
  }

  // Start Three rendering
  window.rendTexture = DESIGNS_DIR + recs[gRecCur].image_url;
  window.needsTexUpdate = true;
  window.rendPause = false;
  rend.style.visibility = 'visible';

  // Handle card selection
  function handleSelect(r) {
    gRecCur = r;
    window.rendTexture = DESIGNS_DIR + recs[r].image_url;
    window.needsTexUpdate = true;
    reRender(!click);
  }

  // Handle reset
  function handleReset() {
    window.rendTexture = DESIGNS_DIR + recs[gRecCur].image_url;
    window.needsTexUpdate = true;
    window.rotAngle=0;
    window.needsContReset=true;
    reRender(!click);
  }
  
  //
  // Return
  //
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} className={"main"}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={()=>{getBack(true)}}>
            <AppsIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1 }} component="div">
             <Link href={recs[gRecCur].vendor_url} target="_blank"> {recs[gRecCur].vendor}</Link> / {recs[gRecCur].name} ({recs[gRecCur].size_mm} mm / {recs[gRecCur].size_st} St.)
          </Typography>

          <Slider style={{ color: '#292929', align:'right', width:'250px', margin:'15px' }}
            step={1.0}
            min={-180}
            max={180}
            defaultValue={window.rotAngle}
            key={`slider-${gRendNum}`}
            onChange={(e,v)=>{window.rotAngle=v}}/>

          <IconButton onClick={()=>handleReset()}>
            <RotationIcon/>
          </IconButton>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: 'none' }) }}>
            <LeftIcon />
          </IconButton>

        </Toolbar>
      </AppBar>

      <DrawerHeader />      
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            background: '#bbaa88'
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />

        {/* Checkroom */}
        <Accordion expanded={bg === 2} onChange={handleBackChange(2)}>
          <AccordionSummary aria-controls="panel1-content" id="panel1-header">
            <ListItemIcon style={{ color: '#292929' }}>
              <CheckroomIcon/>
            </ListItemIcon>
              <Typography>Checkroom</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* Clothing */}
            <ListItem>
              <Slider style={{color: '#292929'}}
                step={1.0}
                min={1}
                max={14}
                marks
                defaultValue={gCloth}
                onChange={handleClothingChange} />
            </ListItem>
            <ListItem>
            <ChromePicker style={{ position:'relative', right:'0px', align:'right'}}
              color={ backColor }
              disableAlpha = {true}
              onChangeComplete={handleBackColorChange} />
            </ListItem>
          </AccordionDetails>
        </Accordion>

        {/* Camera */}
        <Accordion expanded={bg === 0} onChange={handleBackChange(0)}>
          <AccordionSummary aria-controls="panel1-content" id="panel1-header">
            <ListItemIcon style={{ color: '#292929' }}>
              <CameraIcon/>
            </ListItemIcon>
            <Typography>Camera</Typography>
          </AccordionSummary>
        </Accordion>

        {/* Canvas */}
        <Accordion expanded={bg === 1} onChange={handleBackChange(1)}>
          <AccordionSummary aria-controls="panel1-content" id="panel1-header">
            <ListItemIcon style={{ color: '#292929' }}>
              <CanvasIcon/>
            </ListItemIcon>
              <Typography>Canvas</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <ListItem>
            <Slider style={{color: '#292929'}}
              step={1.0}
              min={1}
              max={8}
              marks
              defaultValue={gCanvas}
              canvas={gCanvas}
              onChange={handleCanvasChange} />
          </ListItem>
          <ListItem>
            <ChromePicker style={{position:'relative', right:'0px', align:'right'}}
              color={ backColor }
              disableAlpha = {true}
              onChangeComplete={handleBackColorChange} />
          </ListItem>
          </AccordionDetails>
        </Accordion>

        {/* Cards */}
        <Grid container spacing={0} style={{ position: 'relative', top:'5px', left:'11px', width:'310px'}}>
          {/* ---------------------------------- 0 ---------------------------------*/}
          <Grid item xs={12}>
            <Card sx={{ maxWidth: 280, visibility: recs[0].id < 0 ? 'hidden' : 'visible' }}
                  onClick={()=>handleSelect(0)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image={ DESIGNS_DIR + recs[0].thumb}
                  alt={recs[0].name}/>
                <CardContent align='center'>
                  <Typography variant="body2">
                    <Link href={recs[0].vendor_url} target="_blank"> {recs[0].vendor}</Link> / {recs[0].name}
                  </Typography>
                  <Typography variant="body4" color="text.secondary" sx={{ fontStyle:'italic' }}>
                    {recs[0].size_mm} mm / {recs[0].size_st} St.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          {/* ---------------------------------- 1 ---------------------------------*/}
          <Grid item xs={12}>
             <Card sx={{ maxWidth: 280, visibility: recs[1].id < 0 ? 'hidden' : 'visible' }}
                  onClick={()=>handleSelect(1)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image={ DESIGNS_DIR + recs[1].thumb}
                  alt={recs[1].name}/>
                <CardContent align='center'>
                  <Typography variant="body2">
                    <Link href={recs[1].vendor_url} target="_blank"> {recs[1].vendor}</Link> / {recs[1].name}
                  </Typography>
                  <Typography variant="body4" color="text.secondary" sx={{ fontStyle:'italic' }}>
                    {recs[1].size_mm} mm / {recs[1].size_st} St.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          {/* ---------------------------------- 2 ---------------------------------*/}
          <Grid item xs={12}>
             <Card sx={{ maxWidth: 280, visibility: recs[2].id < 0 ? 'hidden' : 'visible' }} 
                  onClick={()=>handleSelect(2)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image={ DESIGNS_DIR + recs[2].thumb}
                  alt={recs[2].name}/>
                <CardContent align='center'>
                  <Typography variant="body2">
                    <Link href={recs[2].vendor_url} target="_blank"> {recs[2].vendor}</Link> / {recs[2].name}
                  </Typography>
                  <Typography variant="body4" color="text.secondary" sx={{ fontStyle:'italic' }}>
                    {recs[2].size_mm} mm / {recs[2].size_st} St.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          {/* ---------------------------------- 3 ---------------------------------*/}
          <Grid item xs={12}>
             <Card sx={{ maxWidth: 280, visibility: recs[3].id < 0 ? 'hidden' : 'visible' }} 
                  onClick={()=>handleSelect(3)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image={ DESIGNS_DIR + recs[3].thumb}
                  alt={recs[3].name}/>
                <CardContent align='center'>
                  <Typography variant="body2">
                    <Link href={recs[3].vendor_url} target="_blank"> {recs[3].vendor}</Link> / {recs[3].name}
                  </Typography>
                  <Typography variant="body4" color="text.secondary" sx={{ fontStyle:'italic' }}>
                    {recs[3].size_mm} mm / {recs[3].size_st} St.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          {/* ---------------------------------- 4 ---------------------------------*/}
          <Grid item xs={12}>
             <Card sx={{ maxWidth: 280, visibility: recs[4].id < 0 ? 'hidden' : 'visible' }} 
                  onClick={()=>handleSelect(4)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image={ DESIGNS_DIR + recs[4].thumb}
                  alt={recs[4].name}/>
                <CardContent align='center'>
                  <Typography variant="body2">
                    <Link href={recs[4].vendor_url} target="_blank"> {recs[4].vendor}</Link> / {recs[4].name}
                  </Typography>
                  <Typography variant="body4" color="text.secondary" sx={{ fontStyle:'italic' }}>
                    {recs[4].size_mm} mm / {recs[4].size_st} St.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          {/* ---------------------------------- 5 ---------------------------------*/}
          <Grid item xs={12}>
             <Card sx={{ maxWidth: 280, visibility: recs[5].id < 0 ? 'hidden' : 'visible' }} 
                  onClick={()=>handleSelect(5)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image={ DESIGNS_DIR + recs[5].thumb}
                  alt={recs[5].name}/>
                <CardContent align='center'>
                  <Typography variant="body2">
                    <Link href={recs[5].vendor_url} target="_blank"> {recs[5].vendor}</Link> / {recs[5].name}
                  </Typography>
                  <Typography variant="body4" color="text.secondary" sx={{ fontStyle:'italic' }}>
                    {recs[5].size_mm} mm / {recs[5].size_st} St.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          {/* ---------------------------------- 6 ---------------------------------*/}
          <Grid item xs={12}>
             <Card sx={{ maxWidth: 280, visibility: recs[6].id < 0 ? 'hidden' : 'visible' }} 
                  onClick={()=>handleSelect(6)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image={ DESIGNS_DIR + recs[6].thumb}
                  alt={recs[6].name}/>
                <CardContent align='center'>
                  <Typography variant="body2">
                    <Link href={recs[6].vendor_url} target="_blank"> {recs[6].vendor}</Link> / {recs[6].name}
                  </Typography>
                  <Typography variant="body4" color="text.secondary" sx={{ fontStyle:'italic' }}>
                    {recs[6].size_mm} mm / {recs[6].size_st} St.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          {/* ---------------------------------- 7 ---------------------------------*/}
          <Grid item xs={12}>
             <Card sx={{ maxWidth: 280, visibility: recs[7].id < 0 ? 'hidden' : 'visible' }} 
                  onClick={()=>handleSelect(7)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image={ DESIGNS_DIR + recs[7].thumb}
                  alt={recs[7].name}/>
                <CardContent align='center'>
                  <Typography variant="body2">
                    <Link href={recs[7].vendor_url} target="_blank"> {recs[7].vendor}</Link> / {recs[7].name}
                  </Typography>
                  <Typography variant="body4" color="text.secondary" sx={{ fontStyle:'italic' }}>
                    {recs[7].size_mm} mm / {recs[7].size_st} St.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          {/* ---------------------------------- 8 ---------------------------------*/}
          <Grid item xs={12}>
             <Card sx={{ maxWidth: 280, visibility: recs[8].id < 0 ? 'hidden' : 'visible' }} 
                  onClick={()=>handleSelect(8)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image={ DESIGNS_DIR + recs[8].thumb}
                  alt={recs[8].name}/>
                <CardContent align='center'>
                  <Typography variant="body2">
                    <Link href={recs[8].vendor_url} target="_blank"> {recs[8].vendor}</Link> / {recs[8].name}
                  </Typography>
                  <Typography variant="body4" color="text.secondary" sx={{ fontStyle:'italic' }}>
                    {recs[8].size_mm} mm / {recs[8].size_st} St.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          {/* ---------------------------------- 9 ---------------------------------*/}
          <Grid item xs={12}>
             <Card sx={{ maxWidth: 280, visibility: recs[9].id < 0 ? 'hidden' : 'visible' }} 
                  onClick={()=>handleSelect(9)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image={ DESIGNS_DIR + recs[9].thumb}
                  alt={recs[9].name}/>
                <CardContent align='center'>
                  <Typography variant="body2">
                    <Link href={recs[9].vendor_url} target="_blank"> {recs[9].vendor}</Link> / {recs[9].name}
                  </Typography>
                  <Typography variant="body4" color="text.secondary" sx={{ fontStyle:'italic' }}>
                    {recs[9].size_mm} mm / {recs[9].size_st} St.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          {/* ---------------------------------- 10 ---------------------------------*/}
          <Grid item xs={12}>
             <Card sx={{ maxWidth: 280, visibility: recs[10].id < 0 ? 'hidden' : 'visible' }} 
                  onClick={()=>handleSelect(10)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image={ DESIGNS_DIR + recs[10].thumb}
                  alt={recs[10].name}/>
                <CardContent align='center'>
                  <Typography variant="body2">
                    <Link href={recs[10].vendor_url} target="_blank"> {recs[10].vendor}</Link> / {recs[10].name}
                  </Typography>
                  <Typography variant="body4" color="text.secondary" sx={{ fontStyle:'italic' }}>
                    {recs[10].size_mm} mm / {recs[10].size_st} St.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          {/* ---------------------------------- 11 ---------------------------------*/}
          <Grid item xs={12}>
             <Card sx={{ maxWidth: 280, visibility: recs[11].id < 0 ? 'hidden' : 'visible' }} 
                  onClick={()=>handleSelect(11)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image={ DESIGNS_DIR + recs[11].thumb}
                  alt={recs[11].name}/>
                <CardContent align='center'>
                  <Typography variant="body2">
                    <Link href={recs[11].vendor_url} target="_blank"> {recs[11].vendor}</Link> / {recs[11].name}
                  </Typography>
                  <Typography variant="body4" color="text.secondary" sx={{ fontStyle:'italic' }}>
                    {recs[11].size_mm} mm / {recs[11].size_st} St.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Drawer>
   </Box>
  );
}
